/*
パンくずリスト

Markup:
ul.ec-breadcrumb
  li: a Home
  li: a Category
  li: a Product
*/

.ec-breadcrumb {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 5px 0;
  justify-content: flex-start;
  list-style: none;
  margin: 0 auto;
  max-width: 1150px;
  padding: 5px 0px;

  @include media_desktop {
    background-color: #f6f6f6;
    margin-bottom: 20px;
    padding: 5px 5px;
  }

  > li {
    font-size: 0.8rem;
    line-height: 1.2;
    margin: 0;
    padding: 0 25px 0 0;
    position: relative;

    @include media_desktop {
      font-size: 0.9rem;
      padding: 0 30px 0 0;
    }

    &:after {
      border-right: 1px solid #525263;
      border-top: 1px solid #525263;
      bottom: auto;
      content: "";
      display: block;
      height: 6px;
      margin: auto 0;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      width: 6px;
    }

    &:last-child {
      padding-right: 0;

      &:after {
        display: none;
      }
    }

    a, span {
      color: #525263;
      display: block;
      font-weight: 500;

      &:focus {
        color: #525263;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #525263;
        }
      }
    }

    a {
      position: relative;
      text-decoration: none;

      &:after {
        background-color: #525263;
        bottom: -1px;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        margin: 0;
        max-width: 100%;
        position: absolute;
        right: auto;
        transition: width 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
        width: 0;
        z-index: 0;

        &:focus {
          width: 100%;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            width: 100%;
          }
        }
      }
    }
  }
}